// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AtfISoMt {\n  position: relative;\n}\n\n  .AtfISoMt .TXo0YPiv {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: calc(100% - 55px);\n    display: flex;\n    border: var(--red) dashed 1px;\n    color: var(--red);\n    background-color: var(--lightRed);\n    font-size: 13px;\n    font-weight: bold;\n    text-transform: uppercase;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AtfISoMt",
	"deleteBox": "TXo0YPiv"
};
export default ___CSS_LOADER_EXPORT___;
