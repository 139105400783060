// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div.cTM2pcDo {\n  flex-direction: column-reverse;\n  display: flex;\n}\n\n  div.cTM2pcDo label.juF2\\+gwG {\n    position: relative;\n    color: var(--defaultBlack);\n    font-size: 13px;\n    letter-spacing: 0.1px;\n    line-height: 16px;\n    transform: unset;\n    margin-top: 3px\n  }\n\n  div.cTM2pcDo label.juF2\\+gwG.CL2eakBP {\n      color: var(--lightGreyText);\n    }\n\n  div.cTM2pcDo label.juF2\\+gwG.DcwjR-7m {\n      color: var(--brain-primary);\n    }\n\n  div.cTM2pcDo div.oyqbBMsA {\n    color: var(--defaultBlack);\n    font-size: 14px;\n    letter-spacing: 0.1px;\n    line-height: 17px;\n    margin: 0\n  }\n\n  div.cTM2pcDo div.oyqbBMsA::before,\n    div.cTM2pcDo div.oyqbBMsA:hover:not(.uyr0JxZL)::before,\n    div.cTM2pcDo div.oyqbBMsA:hover::before {\n      border-bottom: 1px solid var(--defaultBlack);\n    }\n\n  div.cTM2pcDo div.oyqbBMsA::after {\n      border-bottom: 1px solid var(--brain-primary);\n    }\n\n  div.cTM2pcDo div.oyqbBMsA.CL2eakBP::before,\n      div.cTM2pcDo div.oyqbBMsA.CL2eakBP:hover::before {\n        border-bottom: 1px solid var(--lightGrey);\n      }\n\n  div.cTM2pcDo div.oyqbBMsA.NgEP0xsQ::before,\n    div.cTM2pcDo div.oyqbBMsA.NgEP0xsQ:hover::before {\n      border-bottom: none;\n    }\n\n  div.cTM2pcDo div.oyqbBMsA.iibebr44 {\n      color: var(--defaultBlack);\n    }\n\n  div.cTM2pcDo div.oyqbBMsA > input {\n      padding: 0 0 3px;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textField": "cTM2pcDo",
	"textLabel": "juF2+gwG",
	"disabled": "CL2eakBP",
	"focusedLabel": "DcwjR-7m",
	"textInput": "oyqbBMsA",
	"Mui-disabled": "uyr0JxZL",
	"removeUnderline": "NgEP0xsQ",
	"focusedInput": "iibebr44"
};
export default ___CSS_LOADER_EXPORT___;
