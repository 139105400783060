// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._8-yTtvfM {\n  display: flex;\n}\n\n  ._8-yTtvfM .f8VxgJ57 {\n    font-size: 40px;\n    margin-right: 15px;\n  }\n\n._8-yTtvfM.M0FBRvre .f8VxgJ57 {\n    fill: var(--red);\n  }\n\n._8-yTtvfM.eflLu4St .f8VxgJ57 {\n    fill: var(--orange);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error-dialog": "_8-yTtvfM",
	"error-icon": "f8VxgJ57",
	"level-error": "M0FBRvre",
	"level-warning": "eflLu4St"
};
export default ___CSS_LOADER_EXPORT___;
