// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./arrows/arrow-left.svg";
import ___CSS_LOADER_URL_IMPORT_1___ from "./arrows/arrow-right.svg";
import ___CSS_LOADER_URL_IMPORT_2___ from "./arrows/arrow-top.svg";
import ___CSS_LOADER_URL_IMPORT_3___ from "./arrows/arrow-bottom.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yYxWSW9A .a1MyzLsi {\n  max-width: 378px;\n  padding: 14px;\n  border-radius: 3px;\n  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);\n  background-color: var(--defaultBlack);\n  font-size: 14px;\n  font-weight: normal;\n  line-height: 1.4em;\n  letter-spacing: 0.1px;\n  color: var(--white);\n  white-space: pre-line;\n}\n\n  .yYxWSW9A .a1MyzLsi .ClFohmL5 {\n    font-size: inherit;\n    color: var(--defaultBlack)\n  }\n\n  .yYxWSW9A .a1MyzLsi .ClFohmL5::before {\n      width: inherit;\n      height: inherit;\n      border: none;\n    }\n\n.yYxWSW9A .a1MyzLsi.MUCJOdsX .ClFohmL5 {\n    transform: translateX(15px);\n    width: 15px;\n    height: 21px;\n    margin: 0\n  }\n\n.yYxWSW9A .a1MyzLsi.MUCJOdsX .ClFohmL5::before {\n      background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    }\n\n.yYxWSW9A .a1MyzLsi.e-VqPpEp .ClFohmL5 {\n    transform: translateX(-15px);\n    width: 15px;\n    height: 21px;\n    margin: 0\n  }\n\n.yYxWSW9A .a1MyzLsi.e-VqPpEp .ClFohmL5::before {\n      background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n    }\n\n.yYxWSW9A .a1MyzLsi.lw83xCHl .ClFohmL5 {\n    transform: translateY(15px);\n    width: 22px;\n    height: 15px;\n    margin: 0\n  }\n\n.yYxWSW9A .a1MyzLsi.lw83xCHl .ClFohmL5::before {\n      background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n    }\n\n.yYxWSW9A .a1MyzLsi.WDWgpI02 .ClFohmL5 {\n    transform: translateY(-15px);\n    width: 22px;\n    height: 15px;\n    margin: 0\n  }\n\n.yYxWSW9A .a1MyzLsi.WDWgpI02 .ClFohmL5::before {\n      background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brain-popper": "yYxWSW9A",
	"brain-tooltip": "a1MyzLsi",
	"brain-tooltip-arrow": "ClFohmL5",
	"left": "MUCJOdsX",
	"right": "e-VqPpEp",
	"top": "lw83xCHl",
	"bottom": "WDWgpI02"
};
export default ___CSS_LOADER_EXPORT___;
