// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "button.xGupbsfr {\n  padding: 12px 0 !important;\n  min-height: initial !important;\n  text-align: center !important;\n  border: none;\n  border-top: 1px solid var(--sideBarBorderColor) !important;\n  color: var(--sideBarGreyText);\n  transition:\n    background-color ease-in-out var(--sideBarExpansionTime),\n    width ease-in-out var(--sideBarExpansionTime) !important;\n  font-size: 10px !important;\n  width: var(--sideBarWidth)\n}\n\nbutton.xGupbsfr.dglEG-Js {\n    width: 100%;\n    background-color: #f8f8f8 !important;\n  }\n\n._7FBXyl9T {\n  color: var(--defaultBlack);\n  padding: 25px;\n  background-color: var(--ultraLightGrey);\n}\n\n._7FBXyl9T .FPwfNZwE {\n    width: 50px;\n    margin: 0 auto 25px;\n  }\n\n._7FBXyl9T .joguaard {\n    display: flex;\n    align-items: center;\n    color: inherit\n  }\n\n._7FBXyl9T .joguaard:first-of-type {\n      margin-bottom: 5px;\n    }\n\n._7FBXyl9T .joguaard > :first-of-type {\n      width: 100px;\n    }\n\n._7FBXyl9T .joguaard > :last-of-type {\n      width: 55px;\n      overflow: hidden;\n      white-space: nowrap;\n      text-overflow: ellipsis\n    }\n\n._7FBXyl9T .joguaard > :last-of-type._3k8g1MF1 {\n        justify-content: center;\n      }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"versionDetail": "xGupbsfr",
	"expanded": "dglEG-Js",
	"modal": "_7FBXyl9T",
	"brainLogo": "FPwfNZwE",
	"label": "joguaard",
	"loader": "_3k8g1MF1"
};
export default ___CSS_LOADER_EXPORT___;
