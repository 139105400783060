// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dvAgzDjK {\n  position: relative;\n  width: 388px;\n  border-radius: 3px;\n  color: var(--defaultBlack);\n  font-size: 14px;\n}\n\n  .dvAgzDjK button.XLA3aX1H {\n    --btn-size: 20px;\n\n    position: absolute;\n    right: 20px;\n    top: 20px;\n    color: var(--defaultBlack)\n  }\n\n  .dvAgzDjK button.XLA3aX1H:hover {\n      color: var(--darkGrey);\n      background-color: var(--veryLightGrey);\n    }\n\n  .dvAgzDjK button.XLA3aX1H:active,\n    .dvAgzDjK button.XLA3aX1H:focus {\n      color: var(--darkGrey);\n      background-color: var(--lightGrey);\n    }\n\n  .dvAgzDjK div.V3TDM5vY {\n    padding: 20px;\n  }\n\n  .dvAgzDjK div.V3TDM5vY h3.OPvEybVu {\n      margin: 0 0 14px;\n      color: var(--defaultBlack);\n      font-size: 20px;\n      font-weight: 500;\n      line-height: 1;\n      letter-spacing: 0.14px\n    }\n\n  .dvAgzDjK div.V3TDM5vY h3.OPvEybVu::first-letter {\n        text-transform: uppercase;\n      }\n\n  .dvAgzDjK div.V3TDM5vY p.B1uSU-Ox {\n      margin: 0;\n      color: var(--darkGrey);\n      font-size: 14px;\n      letter-spacing: 0.1px;\n      line-height: 1.71;\n      word-wrap: break-word;\n      word-break: break-word;\n    }\n\n  .dvAgzDjK div.dsad4pDK {\n    display: flex;\n    justify-content: flex-end;\n    padding: 9px;\n    border-top: 1px solid var(--veryLightGrey);\n  }\n\n  .dvAgzDjK div.dsad4pDK .RPVE29o3 {\n      height: 33px\n    }\n\n  .dvAgzDjK div.dsad4pDK .RPVE29o3:not(:first-of-type) {\n        margin-left: 9px;\n      }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "dvAgzDjK",
	"closeButton": "XLA3aX1H",
	"dialog-content": "V3TDM5vY",
	"modalTitle": "OPvEybVu",
	"modalText": "B1uSU-Ox",
	"buttonsContainer": "dsad4pDK",
	"dialog-button": "RPVE29o3"
};
export default ___CSS_LOADER_EXPORT___;
