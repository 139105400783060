// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ePHLknQt {\n  width: 388px;\n  border-collapse: collapse;\n  border-bottom: 1px solid var(--veryLightGrey);\n  margin-top: 17px;\n}\n\n  .ePHLknQt tr {\n    height: 35px;\n    font-size: 13px;\n    color: var(--darkGrey);\n  }\n\n  .ePHLknQt tr:nth-child(odd) {\n    background: var(--veryLightGrey);\n  }\n\n  .ePHLknQt tr:nth-child(even) {\n    background: var(--white);\n  }\n\n  .ePHLknQt tr._8GKLO6X9 {\n    font-weight: bold;\n    background: #fff;\n  }\n\n  .ePHLknQt td {\n    padding: 0 20px;\n  }\n\n.HJa\\+VRSR div._3EF8NJWF {\n    padding: 0;\n  }\n\n.HJa\\+VRSR div._3EF8NJWF h3.RJjZe4Q- {\n      padding: 15px 20px;\n      margin: 0;\n      border-bottom: 1px solid var(--veryLightGrey);\n    }\n\n.b4\\+4ivoW {\n  width: 100%;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "ePHLknQt",
	"firstRow": "_8GKLO6X9",
	"modal": "HJa+VRSR",
	"content": "_3EF8NJWF",
	"title": "RJjZe4Q-",
	"button": "b4+4ivoW"
};
export default ___CSS_LOADER_EXPORT___;
