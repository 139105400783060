// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div._4rkyHomf {\n  display: flex;\n  flex-direction: column;\n  grid-gap: 20px;\n  grid-gap: 20px;\n  gap: 20px;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: 620px;\n  background-color: var(--white);\n  border: 3px solid var(--white);\n  border-radius: 3px;\n  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);\n  outline: none;\n  padding-top: 20px;\n  padding-bottom: 20px;\n}\n\n  div._4rkyHomf ._28eS1vsy {\n    font-weight: bold;\n    font-size: 1.5rem;\n    color: #c23c59;\n    padding-bottom: 4px;\n    text-align: center;\n  }\n\n  div._4rkyHomf .KxFRmCuc {\n    font-weight: 500;\n    font-size: 1.2rem;\n    text-align: center;\n  }\n\n  div._4rkyHomf .u4hLH\\+EX {\n    padding: 24px;\n    background-color: #f8f8f8;\n  }\n\n  div._4rkyHomf .Z\\+lu9ufr {\n    margin-top: 24px;\n    margin-bottom: 24px;\n    margin-left: 20px;\n    display: flex;\n    flex-direction: column;\n    grid-gap: 12px;\n    grid-gap: 12px;\n    gap: 12px;\n  }\n\n  div._4rkyHomf div.ERnHAODX {\n    display: flex;\n    justify-content: flex-end;\n    padding-right: 20px;\n  }\n\n  div._4rkyHomf div.ERnHAODX button {\n      font-size: 12px;\n      width: 80px;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBox": "_4rkyHomf",
	"header": "_28eS1vsy",
	"subHeader": "KxFRmCuc",
	"content": "u4hLH+EX",
	"instructions": "Z+lu9ufr",
	"buttonsContainer": "ERnHAODX"
};
export default ___CSS_LOADER_EXPORT___;
