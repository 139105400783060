// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[dir=\"ltr\"] label.NRy8Z\\+q7 {\n  margin-left: 5px;\n}\n[dir=\"rtl\"] label.NRy8Z\\+q7 {\n  margin-right: 5px;\n}\nlabel.NRy8Z\\+q7 {\n  display: flex;\n  align-items: center;\n  margin-left: 5px;\n  margin-right: 0;\n  margin-top: 10px;\n  margin-top: 10px;\n  margin-bottom: 10px;\n  margin-bottom: 10px;\n}\nlabel.NRy8Z\\+q7 [dir=\"ltr\"] .S2WNfD7- {\n    margin-left: 10px;\n}\nlabel.NRy8Z\\+q7 [dir=\"rtl\"] .S2WNfD7- {\n    margin-right: 10px;\n}\nlabel.NRy8Z\\+q7 .S2WNfD7- {\n    color: var(--defaultBlack);\n    font-size: 14px;\n    letter-spacing: 0.1px;\n    line-height: 14px;\n    margin-left: 10px;\n  }\nlabel.NRy8Z\\+q7 .tlZcOADl {\n    padding: 0;\n  }\nlabel.NRy8Z\\+q7 .tlZcOADl .Ie3J2BAI {\n      height: 18px;\n      width: 18px;\n      border: solid 1px var(--greyText);\n      border-radius: 3px;\n    }\nlabel.NRy8Z\\+q7 .tlZcOADl:hover,\n    label.NRy8Z\\+q7 .tlZcOADl.vreql7tr {\n      color: var(--brain-primary, var(--greyText));\n      background-color: transparent;\n    }\nlabel.NRy8Z\\+q7 .tlZcOADl:hover .Ie3J2BAI, label.NRy8Z\\+q7 .tlZcOADl.vreql7tr .Ie3J2BAI {\n        border: solid 2px var(--brain-primary, var(--greyText));\n      }\nlabel.NRy8Z\\+q7:hover .Ie3J2BAI {\n    border: solid 2px var(--brain-primary, var(--greyText));\n  }\nlabel.NRy8Z\\+q7.bG6AaGwf {\n    pointer-events: none;\n  }\nlabel.NRy8Z\\+q7.bG6AaGwf .S2WNfD7- {\n      color: var(--lightGreyText);\n    }\nlabel.NRy8Z\\+q7.bG6AaGwf .tlZcOADl .Ie3J2BAI {\n        color: var(--lightGreyText);\n        background-color: var(--lightGrey);\n        border-color: var(--lightGrey);\n      }\nlabel.NRy8Z\\+q7.bG6AaGwf .tlZcOADl.vreql7tr .Ie3J2BAI {\n          background-color: var(--white);\n          border-color: var(--lightGrey);\n          color: var(--lightGrey);\n        }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brain-checkbox": "NRy8Z+q7",
	"label": "S2WNfD7-",
	"checkbox-btn": "tlZcOADl",
	"checkbox-icon": "Ie3J2BAI",
	"checked": "vreql7tr",
	"disabled": "bG6AaGwf"
};
export default ___CSS_LOADER_EXPORT___;
