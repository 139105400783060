// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div.vJ\\+8Pvm6 {\n  color: var(--defaultBlack);\n  font-size: 14px;\n  letter-spacing: 0.1px;\n  line-height: 16px;\n  height: 48px;\n  white-space: pre-wrap\n}\ndiv.vJ\\+8Pvm6:hover,\n  div.vJ\\+8Pvm6.xZYneUir,\n  div.vJ\\+8Pvm6.BegS7b4e,\n  div.vJ\\+8Pvm6.BegS7b4e:hover {\n    background-color: var(--veryLightGrey);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"option": "vJ+8Pvm6",
	"focused": "xZYneUir",
	"selected": "BegS7b4e"
};
export default ___CSS_LOADER_EXPORT___;
