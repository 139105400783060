// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sTJHnZl\\+ .jQMYqYf1 {\n    font-size: 14px;\n    font-weight: bold;\n    letter-spacing: 0.1px;\n    margin: 0 0 10px 0;\n  }\n\n    .sTJHnZl\\+ .jQMYqYf1 a {\n      color: var(--brain-primary);\n    }\n  .sTJHnZl\\+ h2 {\n    margin-top: 10px;\n  }\n  .sTJHnZl\\+ .qqlJVC53 {\n    display: flex;\n    align-items: flex-end;\n    justify-content: space-between;\n  }\n  .sTJHnZl\\+ .qqlJVC53 h1 {\n      font-weight: bold;\n      font-size: 30px;\n      line-height: 24px;\n      letter-spacing: 0.21px;\n      color: var(--defaultBlack);\n      margin: 0;\n    }\n  .sTJHnZl\\+ .qqlJVC53 ._07ku3R3V a {\n        margin-right: 15px;\n      }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumbs-header": "sTJHnZl+",
	"breadcrumbs": "jQMYqYf1",
	"title": "qqlJVC53",
	"links": "_07ku3R3V"
};
export default ___CSS_LOADER_EXPORT___;
