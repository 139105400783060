// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._8qZ3a5Gp {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  margin: 14px 0 var(--header-bottom-margin, 40px) 0;\n}\n\n  ._8qZ3a5Gp h1 {\n    font-size: 42px;\n    font-weight: bold;\n    letter-spacing: 0.3px;\n    color: var(--defaultBlack);\n    margin: 0;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main-page-header": "_8qZ3a5Gp"
};
export default ___CSS_LOADER_EXPORT___;
