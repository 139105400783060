// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div.BfYBfKpj {\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\ndiv.cHdsFAgS img.H9E5zNMT {\n    width: 100%;\n    padding: 0 20px;\n    margin-bottom: 50px;\n    min-width: 190px;\n  }\n\ndiv.cHdsFAgS .zooyWDvz {\n    display: flex;\n    justify-content: center;\n    height: 50px;\n  }\n\ndiv.cHdsFAgS .FDzK8rdy {\n    color: var(--color);\n    stroke-linecap: round;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContainer": "BfYBfKpj",
	"signInContainer": "cHdsFAgS",
	"logo": "H9E5zNMT",
	"googleSignInContainer": "zooyWDvz",
	"circular-progress": "FDzK8rdy"
};
export default ___CSS_LOADER_EXPORT___;
