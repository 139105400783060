// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "form.SMkrt\\+7P {\n  margin-bottom: 50px;\n}\n\n  form.SMkrt\\+7P div.tpv3dSkn {\n    display: flex;\n    width: 1150px;\n    align-items: center;\n    justify-content: flex-end;\n    margin: 20px 0;\n  }\n\n  form.SMkrt\\+7P div.tpv3dSkn button:first-of-type {\n      margin-right: 22px;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field-grid": "SMkrt+7P",
	"action-buttons": "tpv3dSkn"
};
export default ___CSS_LOADER_EXPORT___;
