// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div.K\\+4GsJup .spIofYn7 {\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 30px;\n    justify-content: space-between;\n    align-items: flex-start;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"location-page": "K+4GsJup",
	"header": "spIofYn7"
};
export default ___CSS_LOADER_EXPORT___;
