// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Vy16Xom3 {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n}\n\n  .Vy16Xom3 .bRFPit5s {\n    flex: 1;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    white-space: nowrap;\n    line-height: 1.15em;\n  }\n\n  .Vy16Xom3 .yt27aC8D {\n    margin-left: 5px;\n  }\n\n  .Vy16Xom3 .jc9w1tve {\n    color: #e74d5f;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field-label": "Vy16Xom3",
	"label": "bRFPit5s",
	"field-label-tooltip": "yt27aC8D",
	"tooltipError": "jc9w1tve"
};
export default ___CSS_LOADER_EXPORT___;
