// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n  div.Zjak08MU::-webkit-scrollbar {\n    background-color: transparent;\n    width: 9px;\n    height: 9px;\n  }\ndiv.Zjak08MU::-webkit-scrollbar-thumb {\n    background-color: rgba(0, 0, 0, 0.15);\n    border-radius: 4px;\n    border: 2px solid transparent;\n    background-clip: padding-box\n  }\ndiv.Zjak08MU::-webkit-scrollbar-thumb:hover {\n      background-color: rgba(0, 0, 0, 0.2);\n      border-radius: 4px;\n      border: 2px solid transparent;\n      background-clip: padding-box;\n    }\ndiv.Zjak08MU {\n\n  flex-grow: 1;\n  flex-shrink: 1 !important;\n  overflow-y: auto;\n  overflow-x: hidden;\n  min-height: 150px\n}\ndiv.Zjak08MU.eZ6I6NtG {\n    overflow: hidden;\n  }\ndiv.Zjak08MU button.YceqlX6z,\n  div.Zjak08MU a.E2CrZQEU {\n    width: 100%;\n    border: none;\n    display: flex;\n    align-items: center;\n    position: relative\n  }\ndiv.Zjak08MU button.YceqlX6z.nRCMtG5N, div.Zjak08MU a.E2CrZQEU.nRCMtG5N {\n      color: var(--sideBarBlue);\n    }\ndiv.Zjak08MU button.YceqlX6z:hover, div.Zjak08MU a.E2CrZQEU:hover {\n      background-color: var(--sideBarBgColor);\n    }\ndiv.Zjak08MU button.YceqlX6z span.gDTf6zf1, div.Zjak08MU a.E2CrZQEU span.gDTf6zf1 {\n      flex-grow: 1;\n    }\ndiv.Zjak08MU button.YceqlX6z svg:first-child, div.Zjak08MU a.E2CrZQEU svg:first-child {\n      width: var(--sideBarWidth);\n      flex-shrink: 0;\n      padding: 0 8px;\n    }\ndiv.Zjak08MU button.YceqlX6z svg:last-child, div.Zjak08MU a.E2CrZQEU svg:last-child {\n      width: 24px;\n      flex-shrink: 0;\n      margin: 0 12px;\n    }\ndiv.Zjak08MU a.E2CrZQEU {\n    text-decoration: none;\n    padding: 0 0 0 var(--sideBarWidth);\n    min-height: calc(0.75 * var(--sideBarWidth))\n  }\ndiv.Zjak08MU a.E2CrZQEU:last-of-type {\n      margin-bottom: 5px;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navTree": "Zjak08MU",
	"collapsed": "eZ6I6NtG",
	"button": "YceqlX6z",
	"link": "E2CrZQEU",
	"active": "nRCMtG5N",
	"text": "gDTf6zf1"
};
export default ___CSS_LOADER_EXPORT___;
