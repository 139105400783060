// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div.Ms-oq3wn {\n  display: flex;\n  flex-direction: column;\n}\n\n  div.Ms-oq3wn span {\n    line-height: 1.1em;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap\n  }\n\n  div.Ms-oq3wn span:first-of-type {\n      font-size: 14px;\n      margin-bottom: 5px;\n    }\n\n  div.Ms-oq3wn span:last-of-type {\n      font-size: 11px;\n      color: var(--darkGrey);\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user-label": "Ms-oq3wn"
};
export default ___CSS_LOADER_EXPORT___;
