// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".a5g7KEk\\+ {\n  align-self: flex-end;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"async-select-descriptor": "a5g7KEk+"
};
export default ___CSS_LOADER_EXPORT___;
