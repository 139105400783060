// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tkC4WcNB {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n}\n\n  .tkC4WcNB .nXGrRO9j {\n    flex: 1;\n    text-overflow: ellipsis;\n    overflow: hidden;\n    white-space: nowrap;\n    line-height: 1.15em;\n  }\n\n  .tkC4WcNB .BmZ6wuMj {\n    margin-left: 5px;\n  }\n\n  .tkC4WcNB .QtfWuZoD {\n    font-size: 14px;\n    height: 37px;\n    display: flex;\n    text-transform: uppercase;\n    justify-content: center;\n    align-items: center;\n    padding: 0 18px;\n    color: var(--brain-primary);\n    border-radius: 3px;\n    border: 1px solid var(--brain-primary)\n  }\n\n  .tkC4WcNB .QtfWuZoD:hover {\n      background-color: var(--brain-translucent);\n    }\n\n  .tkC4WcNB .QtfWuZoD img {\n      margin-right: 7px;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"descriptor-label": "tkC4WcNB",
	"label": "nXGrRO9j",
	"descriptor-label-tooltip": "BmZ6wuMj",
	"link": "QtfWuZoD"
};
export default ___CSS_LOADER_EXPORT___;
