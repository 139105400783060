// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div.pSYZsOB- {\n  color: var(--defaultBlack);\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  margin-right: 2px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"single-value": "pSYZsOB-"
};
export default ___CSS_LOADER_EXPORT___;
