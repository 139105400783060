// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div.vohwdprk {\n  display: flex;\n  align-items: center;\n  padding: 1px 0;\n  border-bottom: 1px solid var(--sideBarBorderColor);\n}\n\n  div.vohwdprk > button {\n    border: none;\n    margin: 0 12px;\n    border-radius: 50%;\n    min-height: initial !important\n  }\n\n  div.vohwdprk > button.xieX\\+2-S {\n      color: var(--sideBarBlue);\n    }\n\n  div.vohwdprk > button:hover {\n      background-color: var(--sideBarBgColor);\n    }\n\n  div.vohwdprk > button > svg {\n      width: 24px;\n      height: 24px;\n      display: block;\n    }\n\n  div.vohwdprk div._7ndjSWgz {\n    width: var(--sideBarWidth);\n    flex-shrink: 0;\n    display: flex;\n    justify-content: center;\n  }\n\n  div.vohwdprk div._7ndjSWgz img {\n      width: 85%;\n    }\n\n  div.vohwdprk div.YsW2\\+YIe {\n    flex-grow: 1;\n    font-size: 27px;\n    font-weight: 300;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brainTitle": "vohwdprk",
	"active": "xieX+2-S",
	"logoContainer": "_7ndjSWgz",
	"title": "YsW2+YIe"
};
export default ___CSS_LOADER_EXPORT___;
