// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\ndiv._5opqntm1 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: var(--darkGrey);\n  font-size: 12px;\n  line-height: 21px;\n  letter-spacing: 0.07px;\n  min-width: var(--layout-content-min-width);\n  padding: 10px;\n  background-color: var(--veryLightGrey);\n}\n\n  div._5opqntm1 > a {\n    font-weight: bold;\n    color: var(--brain-primary);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bug-report-footer": "_5opqntm1"
};
export default ___CSS_LOADER_EXPORT___;
