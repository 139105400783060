// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IRp2lLLU {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  color: var(--color);\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressIcon": "IRp2lLLU"
};
export default ___CSS_LOADER_EXPORT___;
