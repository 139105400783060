// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._8fP7oSvz {\n  display: flex;\n  flex-direction: row;\n}\n\n  ._8fP7oSvz .VyeCEv0j {\n    flex: 1\n  }\n\n  ._8fP7oSvz .VyeCEv0j.U4FwY6Y7 input {\n      color: var(--red);\n    }\n\n  ._8fP7oSvz .VyeCEv0j input {\n      font-size: 13px;\n    }\n\n  ._8fP7oSvz .aDKYWS4s input {\n    color: var(--lightGrey);\n  }\n\n  ._8fP7oSvz .cApy68Pq svg {\n    visibility: hidden;\n    width: 15px;\n    height: 15px\n  }\n\n  ._8fP7oSvz .cApy68Pq svg:hover {\n      color: var(--red);\n    }\n\n._8fP7oSvz:hover .cApy68Pq svg {\n      visibility: visible;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_8fP7oSvz",
	"label": "VyeCEv0j",
	"labelDelete": "U4FwY6Y7",
	"disabled": "aDKYWS4s",
	"trash": "cApy68Pq"
};
export default ___CSS_LOADER_EXPORT___;
