// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._9DqTyP-C {\n  margin-top: 10%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  color: var(--defaultBlack);\n}\n\n  ._9DqTyP-C .xjxCTQfP {\n    font-size: 24px;\n  }\n\n  ._9DqTyP-C .B8G6IB7Z {\n    font-size: 16px;\n  }\n\n  ._9DqTyP-C .Gm26wjos {\n    margin-top: 20px;\n  }\n\n  ._9DqTyP-C .X1Z3Cw08 {\n    width: 194px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unauthorized": "_9DqTyP-C",
	"main": "xjxCTQfP",
	"subtitle": "B8G6IB7Z",
	"button": "Gm26wjos",
	"image": "X1Z3Cw08"
};
export default ___CSS_LOADER_EXPORT___;
