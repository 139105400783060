// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UJMol\\+km {\n  padding: 200px 0;\n  width: 764px;\n  text-align: center;\n  margin: 0 auto;\n}\n\n  .UJMol\\+km .oPV9io2A {\n    width: 235px;\n    height: 337px;\n  }\n\n  .UJMol\\+km .sXAzJhOt {\n    font-size: 14px;\n    line-height: 1.57;\n    letter-spacing: 0.1px;\n    margin: 0 auto;\n    width: -moz-fit-content;\n    width: fit-content;\n    max-width: 764px;\n    overflow-x: hidden;\n    text-align: left\n  }\n\n  .UJMol\\+km .sXAzJhOt > * {\n      width: -moz-fit-content;\n      width: fit-content;\n    }\n\n  .UJMol\\+km .sXAzJhOt .wFQWTmZ1 {\n      font-weight: bold;\n    }\n\n  .UJMol\\+km .sXAzJhOt ._7IhMoDRg {\n      font-size: 0.9em;\n      white-space: pre;\n      max-height: 150px;\n      overflow: scroll;\n    }\n\n  .UJMol\\+km .X3SqmtgJ {\n    margin: 22px 0 47px 0;\n    font-size: 13px;\n    letter-spacing: 0.5px;\n  }\n\n  .UJMol\\+km hr {\n    width: 100%;\n    height: 1px;\n    border: solid 1px var(--lightGrey);\n  }\n\n  .UJMol\\+km .Ihibn7qy {\n    font-size: 14px;\n    letter-spacing: 0.1px;\n    font-weight: bold;\n    line-height: 1.56px;\n    margin-top: 19px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "UJMol+km",
	"robot": "oPV9io2A",
	"inner-text": "sXAzJhOt",
	"error-title": "wFQWTmZ1",
	"stack": "_7IhMoDRg",
	"button": "X3SqmtgJ",
	"bottom-text": "Ihibn7qy"
};
export default ___CSS_LOADER_EXPORT___;
