// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qTgs1pMd {\n  margin-top: 15px;\n  border: 1px solid var(--lightGrey);\n}\n\n  .qTgs1pMd .EA6SyhEW {\n    color: var(--defaultBlack);\n    display: flex;\n    font-size: 14px;\n    line-height: 16px;\n    font-weight: bold;\n    letter-spacing: 0.1px;\n  }\n\n  .qTgs1pMd .Aus\\+fBXm {\n    margin-top: 3px;\n    border-bottom: 1px solid var(--lightGrey);\n  }\n\n  .qTgs1pMd ._8AFC1zlY {\n    display: flex;\n    padding: 10px;\n  }\n\n  .qTgs1pMd ._8AFC1zlY > * {\n      flex-grow: 1\n    }\n\n  .qTgs1pMd ._8AFC1zlY > *:first-child {\n        margin-right: 20px;\n      }\n\n  .qTgs1pMd ._8AFC1zlY._41srPQjK {\n      color: var(--greyText);\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"openingHours": "qTgs1pMd",
	"label": "EA6SyhEW",
	"separator": "Aus+fBXm",
	"toFrom": "_8AFC1zlY",
	"inactive": "_41srPQjK"
};
export default ___CSS_LOADER_EXPORT___;
