// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div.NKWk6kys {\n  width: var(--size);\n  height: var(--size);\n  color: var(--color);\n  text-align: center;\n  font-size: 18px;\n  font-weight: bold;\n  letter-spacing: 0.13px;\n}\n\n  div.NKWk6kys p {\n    max-width: 350px;\n    min-height: 38px;\n  }\n\ndiv._0BRT3saQ {\n  color: var(--color);\n  stroke-linecap: round;\n}\n\nsvg.I77PTZO6 {\n  width: 50px;\n  height: 50px;\n}\n\ndiv.Nn0XwoyJ {\n  z-index: 1\n}\n\ndiv.Nn0XwoyJ:not(._5DWBzVGO) {\n    background-color: rgba(255, 255, 255, 0.8);\n  }\n\nbutton.\\+5Zin9JT {\n  color: var(--color);\n  border: 1px solid var(--color)\n}\n\nbutton.\\+5Zin9JT:hover {\n    background-color: rgba(255, 255, 255, 0.9);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading-status": "NKWk6kys",
	"circular-progress": "_0BRT3saQ",
	"error-icon": "I77PTZO6",
	"backdrop": "Nn0XwoyJ",
	"invisibleBackdrop": "_5DWBzVGO",
	"dismiss-error-button": "+5Zin9JT"
};
export default ___CSS_LOADER_EXPORT___;
