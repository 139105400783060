// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div.w7iXIsy8 {\n  grid-column: 1 / -1;\n  margin-bottom: 10px;\n  flex-direction: column-reverse;\n  background-color: var(--white);\n  border-radius: 3px;\n}\n\n  div.w7iXIsy8 .qi1VAlud {\n    color: var(--defaultBlack);\n    font-size: 13px;\n    line-height: 16px;\n    letter-spacing: 0.1px;\n    margin-bottom: 3px;\n    outline: transparent;\n  }\n\n  div.w7iXIsy8 textarea:focus ~ .qi1VAlud {\n    color: var(--brain-primary);\n  }\n\n  div.w7iXIsy8 textarea:disabled ~ .qi1VAlud {\n    color: var(--lightGreyText);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textarea-field": "w7iXIsy8",
	"label": "qi1VAlud"
};
export default ___CSS_LOADER_EXPORT___;
