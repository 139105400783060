// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cftu2X2L {\n  align-self: flex-end;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select-descriptor": "cftu2X2L"
};
export default ___CSS_LOADER_EXPORT___;
