// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div.FJMlNp6q {\n  height: 2px;\n  width: 100%;\n  margin: 0;\n  display: flex;\n  position: fixed;\n  top: 0;\n  z-index: 999;\n  color: #3f51b5;\n}\n\ndiv.FJMlNp6q::before {\n  height: 2px;\n  width: 100%;\n  margin: 0;\n  content: '';\n  background-color: currentColor;\n  animation: _7jwzJQps 1.5s cubic-bezier(0.4, 0, 0.2, 1) infinite;\n}\n\n@keyframes _7jwzJQps {\n  0% {\n    margin-left: 0;\n    margin-right: 100%;\n  }\n\n  50% {\n    margin-left: 25%;\n    margin-right: 0%;\n  }\n\n  100% {\n    margin-left: 100%;\n    margin-right: 0;\n  }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "FJMlNp6q",
	"running-progress": "_7jwzJQps"
};
export default ___CSS_LOADER_EXPORT___;
