// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "a.id5GT2Oc,\nbutton.tfG4n45a {\n  border: none;\n  border-top: 1px solid var(--sideBarBorderColor);\n  display: flex;\n  align-items: center;\n  position: relative\n}\na.id5GT2Oc.GoAnVbMn, button.tfG4n45a.GoAnVbMn {\n    color: var(--sideBarBlue);\n  }\na.id5GT2Oc.GoAnVbMn img._2F9jqa8t, button.tfG4n45a.GoAnVbMn img._2F9jqa8t {\n      opacity: 1;\n    }\na.id5GT2Oc:hover, button.tfG4n45a:hover {\n    background-color: var(--sideBarBgColor);\n  }\na.id5GT2Oc img, button.tfG4n45a img {\n    width: var(--sideBarWidth);\n    flex-shrink: 0;\n    padding: 0 8px\n  }\na.id5GT2Oc img._2F9jqa8t, button.tfG4n45a img._2F9jqa8t {\n      position: absolute;\n      left: 0;\n      opacity: 0;\n      transition: opacity ease-in-out 50ms;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "id5GT2Oc",
	"button": "tfG4n45a",
	"active": "GoAnVbMn",
	"activeImg": "_2F9jqa8t"
};
export default ___CSS_LOADER_EXPORT___;
