// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yiJNuPrZ {\n  display: grid;\n  grid-template-columns: repeat(4, minmax(180px, 240px));\n  grid-auto-rows: minmax(76px, max-content);\n  grid-gap: 20px 63px;\n  grid-gap: 20px 63px;\n  gap: 20px 63px;\n}\n\n.jk4wTlce {\n  padding: 0;\n}\n\n._8MTTtKDA {\n  padding: 25px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fields": "yiJNuPrZ",
	"backdrop": "jk4wTlce",
	"body": "_8MTTtKDA"
};
export default ___CSS_LOADER_EXPORT___;
