// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cMmWQgSs {\n  margin-top: 40px;\n}\n\ndiv.LXaPla0U {\n  margin-top: 20px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n}\n\ndiv.LXaPla0U > :first-child {\n    margin-right: 27px;\n    letter-spacing: 0.5px;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "cMmWQgSs",
	"buttons": "LXaPla0U"
};
export default ___CSS_LOADER_EXPORT___;
