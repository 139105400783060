import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';

import BrainButton from '@shared/components/BrainButton';

import classes from './styles.scss';

export default function PopupsBlockerAlert({ isOpen, handleSignOut }) {
  const onDone = () => {
    window.location.reload();
    handleSignOut();
  };

  return (
    <Modal open={isOpen}>
      <div className={classes.modalBox}>
        <div>
          <h2 className={classes.header}>No Access to Google Drive</h2>
          <h5 className={classes.subHeader}>Access is required to continue</h5>
        </div>
        <div className={classes.content}>
          <p>
            To allow Google Drive access, please enable pop-ups for this site in your
            browser settings:
          </p>
          <div className={classes.instructions}>
            <p>
              <strong>Chrome:</strong> Go to Settings &gt; Privacy and security &gt; Site
              settings &gt; Pop-ups and redirects. Under &quot;Allowed to send pop-ups and
              redirects,&quot; add BRAIN to the allowed sites.
            </p>
            <p>
              <strong>Firefox:</strong> Go to Settings &gt; Privacy & Security &gt;
              Permissions &gt; Block pop-up windows. Click &quot;Exceptions...&quot; Add
              &quot;BRAIN&quot; as exception.
            </p>
            <p>
              <strong>Safari:</strong> Open Safari &gt; Settings for This Website in the
              menu. Set &quot;Pop-up Windows&quot; to Allow.
            </p>
          </div>
          Once you&apos;ve enabled pop-ups, click Done below. The page will refresh to
          apply these settings and you’ll automatically be signed in again.
        </div>
        <div className={classes.buttonsContainer}>
          <BrainButton variant="primary" onClick={onDone}>
            Done
          </BrainButton>
        </div>
      </div>
    </Modal>
  );
}

PopupsBlockerAlert.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleSignOut: PropTypes.func.isRequired,
};
