// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div.o5ukZtwl {\n  position: relative;\n  display: flex;\n  flex-direction: column-reverse;\n}\n\n  div.o5ukZtwl .E0eTmd2n {\n    position: relative;\n    color: var(--defaultBlack);\n    font-size: 13px;\n    letter-spacing: 0.1px;\n    line-height: 16px;\n    transform: unset;\n    margin-top: 3px\n  }\n\n  div.o5ukZtwl .E0eTmd2n.L-153TmU {\n      color: var(--brain-primary);\n    }\n\n  div.o5ukZtwl .E0eTmd2n.obvJrr1a {\n      color: var(--lightGreyText);\n    }\n\n  div.o5ukZtwl ._4lb4qKFT {\n    color: var(--defaultBlack);\n    font-size: 14px;\n    letter-spacing: 0.1px;\n    line-height: 17px;\n    margin: 0\n  }\n\n  div.o5ukZtwl ._4lb4qKFT::before,\n    div.o5ukZtwl ._4lb4qKFT:hover::before {\n      border-bottom: 1px solid var(--defaultBlack);\n    }\n\n  div.o5ukZtwl ._4lb4qKFT::after {\n      border-bottom: 1px solid var(--brain-primary);\n      transition: none;\n    }\n\n  div.o5ukZtwl ._4lb4qKFT.L-153TmU::before {\n        border-bottom: 1px solid var(--brain-primary);\n      }\n\n  div.o5ukZtwl ._4lb4qKFT.L-153TmU::after {\n        transform: unset;\n      }\n\n  div.o5ukZtwl ._4lb4qKFT.obvJrr1a::before {\n      border-bottom: 1px solid var(--lightGrey);\n    }\n\n  div.o5ukZtwl ._4lb4qKFT .iWm-gRIK {\n      display: flex;\n      height: max-content;\n      max-height: 68px;\n      padding: 0 0 3px;\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textField": "o5ukZtwl",
	"label": "E0eTmd2n",
	"focused": "L-153TmU",
	"disabled": "obvJrr1a",
	"inputField": "_4lb4qKFT",
	"input": "iWm-gRIK"
};
export default ___CSS_LOADER_EXPORT___;
