// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div.gggkYPaK {\n  flex-direction: column-reverse;\n  display: flex;\n}\n\n  div.gggkYPaK label.\\+BK9ORYk {\n    position: relative;\n    color: var(--defaultBlack);\n    font-size: 13px;\n    letter-spacing: 0.1px;\n    line-height: 16px;\n    transform: unset;\n    margin-top: 3px\n  }\n\n  div.gggkYPaK label.\\+BK9ORYk.F8hcwU10 {\n      color: var(--lightGreyText);\n    }\n\n  div.gggkYPaK label.\\+BK9ORYk.FcowEXIL {\n      color: var(--brain-primary);\n    }\n\n  div.gggkYPaK label.\\+BK9ORYk._8nJGrAwU {\n      color: var(--red);\n    }\n\n  div.gggkYPaK div.kLjMYvU5 {\n    color: var(--defaultBlack);\n    font-size: 14px;\n    letter-spacing: 0.1px;\n    line-height: 17px;\n    margin: 0\n  }\n\n  div.gggkYPaK div.kLjMYvU5::before,\n    div.gggkYPaK div.kLjMYvU5:hover:not(.mMdDuHwx)::before,\n    div.gggkYPaK div.kLjMYvU5:hover::before {\n      border-bottom: 1px solid var(--defaultBlack);\n    }\n\n  div.gggkYPaK div.kLjMYvU5.F8hcwU10::before,\n      div.gggkYPaK div.kLjMYvU5.F8hcwU10:hover::before {\n        border-bottom: 1px solid var(--lightGrey);\n      }\n\n  div.gggkYPaK div.kLjMYvU5._0emhWH1W {\n      color: var(--defaultBlack);\n    }\n\n  div.gggkYPaK div.kLjMYvU5 > input {\n      padding: 0 0 3px;\n    }\n\n  div.gggkYPaK .e1dPG7\\+e {\n    color: var(--lightGreyText);\n    margin-bottom: 2px\n  }\n\n  div.gggkYPaK .e1dPG7\\+e:hover {\n      background-color: transparent;\n      cursor: pointer;\n      color: var(--greyText);\n    }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textField": "gggkYPaK",
	"textLabel": "+BK9ORYk",
	"disabled": "F8hcwU10",
	"focusedLabel": "FcowEXIL",
	"error": "_8nJGrAwU",
	"textInput": "kLjMYvU5",
	"Mui-disabled": "mMdDuHwx",
	"focusedInput": "_0emhWH1W",
	"clearIcon": "e1dPG7+e"
};
export default ___CSS_LOADER_EXPORT___;
