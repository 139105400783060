// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OYLb0ROc {\n  margin-left: 5px;\n  text-transform: uppercase;\n  font-weight: 600;\n  font-size: 13px\n}\n.OYLb0ROc.MiV6tDSH {\n    color: var(--green);\n  }\n.OYLb0ROc.HQdVJxqm {\n    color: var(--orange);\n  }\n.OYLb0ROc.qRU06GTo {\n    color: var(--red);\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemStatus": "OYLb0ROc",
	"pass": "MiV6tDSH",
	"ongoing": "HQdVJxqm",
	"fail": "qRU06GTo"
};
export default ___CSS_LOADER_EXPORT___;
